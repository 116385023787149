import 'slick-carousel';

const $sliderGallery = $('.js-slider-gallery')

/**
 * Init slick slider.
 *
 * @param  {obj}
 * @return {void}
 */
$sliderGallery.slick({
	slidesToShow: 1,
	slidesToScroll: 1,
	infinite: true,
	dots: false,
	mobileFirst: true,
	prevArrow: null,
	nextArrow: null,
	responsive: [{
		breakpoint: 768,
		settings: 'unslick'
	}]
});
