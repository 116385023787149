const accordion = document.getElementsByClassName("js-accordion");
let viewportWidth = window.innerWidth || document.documentElement.clientWidth;

/**
 * Accordion.
 *
 * @param  {obj}
 * @return {void}
 */
if (typeof(accordion) != 'undefined' && accordion != null) {
	if (viewportWidth < 768) {
		for (i = 0; i < accordion.length; i++) {
			accordion[i].addEventListener("click", function() {
				this.classList.toggle("active");

				const panel = this.nextElementSibling;
				panel.classList.toggle('visible')
			});
		}
	}
}
