import lozad from 'lozad'

/**
 * Init lazyload.
 *
 * @param  {obj}
 * @return {void}
 */
document.addEventListener("DOMContentLoaded", function() {
	const observer = lozad();
	observer.observe();
})
