/**
 * Change image src.
 *
 * @type {obj}
 */
let viewportWidth = window.innerWidth || document.documentElement.clientWidth;

const images = document.getElementsByClassName('lozad');
	for (let i = 0; i < images.length; i++) {
		const dataDesktop = images[i].getAttribute('data-desktop')
		const dataMobile = images[i].getAttribute('data-mobile')
		let dataSrc = images[i].getAttribute('data-src')

		if (viewportWidth > 767) {
			images[i].dataset.src = dataDesktop
		} else {
			images[i].dataset.src = dataMobile
		}

		images[i].onclick = function(event) {
			event.preventDefault()
			this.nextElementSibling.classList.toggle('visible')
		}
	}
