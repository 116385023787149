const $field = $('.js-field')

/**
 * Focus animation.
 *
 * @param  {obj}
 * @return {void}
 */
$field.each(function() {
	const $this = $(this)
	const $closestRow = $this.closest('.form__row')

	$this.focus(function() {
		$closestRow.addClass('active')
	})

	$this.blur(function() {
		if ($this.val().length < 1) {
			$closestRow.removeClass('active')
			$closestRow.removeClass('active-success')
			$closestRow.removeClass('active-error')
		}
	})

	$this.on('keyup', function() {
		if ($this.val().length < 3) {
			$closestRow.addClass('active-error')
			$closestRow.removeClass('active-success')
		}

		if ($this.val().length > 3) {
			$closestRow.addClass('active-success')
			$closestRow.removeClass('active-error')
		}
	})
})
