import 'video.js';

const videoBox = $('.js-video')

/**
 * Run videojs.
 *
 * @param  {obj}
 * @return {void}
 */
videoBox.on('click', function(event) {
    $(this).addClass('played')
})
