/**
 * Accordion.
 * @param  {obj} $accordion
 * @return {void}
 */
const initAccordions = ($accordion = $('.js-accordion-alt')) => {
    //Hide the inactive sections
    $('.accordion__section').not('.is-current').find('.accordion__body').hide()

    //Handle the show/hide logic
    $accordion.on('click', '.accordion__head', function (event) {
        const $accordionSection = $(this).closest('.accordion__section');
        const $accordionBody = $accordionSection.find('.accordion__body');

        $accordionBody.stop().slideToggle();

        $accordionSection.toggleClass('is-current');

        $accordionSection.siblings().removeClass('is-current')
                .find('.accordion__body').slideUp();
    });
}

initAccordions();
