const menuOpen = document.querySelector('.js-header-menu-trigger');
const headerMenu = document.querySelector('.js-header-menu');
const menuClose = document.querySelector('.js-menu-close');
const body = document.querySelector('body');

if (typeof(menuOpen) != 'undefined' && menuOpen != null) {
	/**
	 * Handle Menu open
	 *
	 * @param  {Event}
	 * @return {Void}
	 */
	menuOpen.onclick = function(event) {
		event.preventDefault();
	  	headerMenu.classList.add('open');
	  	body.classList.toggle('overflow-hidden');
	}

	menuClose.onclick = function(event) {
		event.preventDefault();
	  	headerMenu.classList.remove('open');
	  	body.classList.remove('overflow-hidden');
	}
}
