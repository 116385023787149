const $moreButton = $('.js-menu-more')

/**
 * Open menu more.
 *
 * @param  {obj}
 * @return {void}
 */
$(document).on("click", ".js-menu-more", function(event) {
	const $this = $(this)
	const $notThis = $moreButton.not(this)

	event.preventDefault()
	event.stopPropagation();

	$notThis.siblings('.menu__inner').removeClass('visible')
	$this.siblings('.menu__inner').toggleClass('visible');
});


//Hide menu more on empty click
$(window).click(function() {
	$('.menu__inner').removeClass('visible')
});


