const dropdownTrigger = document.querySelectorAll('.js-dropdown-trigger');
const dropdownTriggerMobile = document.getElementsByClassName('js-dropdown-trigger-mobile')[0]
const dropdownTriggerAlt = document.querySelectorAll('.js-dropdown-trigger-alt')[0];
const dropdownAlt = document.querySelectorAll('.js-dropdown-alt')[0];
const dropdownAltAside = document.querySelectorAll('.js-dropdown-alt')[1];
const notificationsDropdownTrigger = document.querySelectorAll('.js-dropdown-notifications-trigger')[0]
const notificationsDropdown = document.querySelectorAll('.js-dropdown-notifications')[0]

/**
 * Append Has dropdown to parent
 *
 * @return {Void}
 */
dropdownTrigger.forEach(function() {
	const closestLi = dropdownTrigger[0].closest('li')

	closestLi.classList.add('has-dropdown');
});

/**
 * Profile dropdown desktop.
 *
 * @param  {obj}
 * @return {void}
 */
dropdownTriggerAlt.onclick = function(event) {
	event.preventDefault();
  	dropdownAlt.classList.toggle('is-active');
}

/**
 * Profile dropdown mobile.
 *
 * @param  {obj}
 * @return {void}
 */
dropdownTriggerMobile.onclick = function(event) {
	event.preventDefault();
  	dropdownAltAside.classList.toggle('is-active');
}

notificationsDropdownTrigger.onclick = function(event) {
	event.preventDefault();
  	notificationsDropdown.classList.toggle('is-active');
}
