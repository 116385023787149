import 'slick-carousel';

const $sliderArticles = $('.js-slider-articles')

/**
 * Init slick slider.
 *
 * @param  {obj}
 * @return {void}
 */
$sliderArticles.slick({
	infinite: true,
	slidesToShow: 3,
	slidesToScroll: 1,
	responsive: [{
			breakpoint: 1024,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				infinite: true,
				dots: true,
				prevArrow: null,
				nextArrow: null,
			}
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				infinite: true,
				dots: true,
				prevArrow: null,
				nextArrow: null,
			}
		}
	]
});
