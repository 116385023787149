const header = document.querySelector('.js-header')
const wrapper = document.querySelector('.wrapper')

if (typeof(header) != 'undefined' && header != null) {
	/**
	 * Sticky header init.
	 *
	 * @return {boolean}
	 */
	const fixed = header.offsetTop;

	function stickyFunction() {
		if (window.pageYOffset > fixed) {
			let headerHeight = header.offsetHeight + 'px'
			header.classList.add('fixed');
			wrapper.style.paddingTop = headerHeight
		} else {
			header.classList.remove('fixed');
			wrapper.style.paddingTop = '0'
		}
	}

	window.onscroll = function() {stickyFunction()};
}
