/*
 * Modules
 */

import './modules/burger';
import './modules/dropdown';
import './modules/articleMore';
import './modules/mobileImages';
import './modules/sliderArticles';
import './modules/sliderGallery';
import './modules/videoJs';
import './modules/lazyLoad';
import './modules/magnificPopup';
import './modules/stickyHeader';
import './modules/datePicker';
import './modules/accordion';
import './modules/accordionAlt';
import './modules/inputFocus';
import './modules/openMenu';
import './modules/showComments';
import './modules/searchPopup';
