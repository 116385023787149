const commentsSection = document.querySelectorAll('.js-comment-section')[0];
const commentsButton = document.querySelectorAll('.js-show-comments')[0];
const comments = document.querySelectorAll('.js-comments')[0];
const commentsTextbox = document.querySelectorAll('.js-comments-textbox')[0];

/**
 * Append Has dropdown to parent
 *
 * @return {Void}
 */
if (typeof(comments) != 'undefined' && comments != null) {
	commentsButton.onclick = function(event) {
		event.preventDefault();
	  	commentsButton.classList.toggle('open');
	  	commentsSection.classList.toggle('open');
	  	comments.classList.toggle('visible');
	  	commentsTextbox.classList.toggle('visible');
	}
}
