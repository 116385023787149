const burger = document.querySelector('.js-burger-trigger');
const body = document.querySelector('body');
const sidebar = document.querySelector('.js-sidebar');

if (typeof(burger) != 'undefined' && burger != null) {
	/**
	 * Handle Burger Trigger
	 *
	 * @param  {Event} event
	 * @return {Void}
	 */
	burger.onclick = function(event) {
		event.preventDefault();
	  	burger.classList.toggle('btn-burger--triggered');
	  	body.classList.toggle('overflow-hidden');
	  	sidebar.classList.toggle('is-active');
	}
}
